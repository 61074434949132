import { ReactElement, useEffect, useState, useRef } from "react";
import { Box, Flex } from "@chakra-ui/layout";
import {
 Grid,
 Drawer,
 useDisclosure,
 DrawerOverlay,
 DrawerContent,
 DrawerHeader,
 DrawerBody,
 VStack,
 Heading,
 Text,
 GridItem,
 Divider,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import Card from "../Card";
import { orderBy } from "lodash";

interface TCustomFields {
 name: string;
 value: string;
 typeData: number;
 currencyCode: string;
 description: string;
}
interface TData {
 id: string;
 name: string;
 description: string;
 currency: string;
 totalCostToCurrency: number;
 totalCost: number;
 unitCost: number;
 customFields: TCustomFields[];
 currencyConversionExchangeRate: number;
 totalCostPricing: {
  amount: number;
  currency: {
   code: string;
   description: string;
   id: string;
  };
 };
 convertedCurrency: {
  code: string;
 };
}

const CostDriverTable = ({ costDriver }: any): ReactElement => {
 const [dataOnclick, setDataOnClick] = useState<TData>();
 const ref = useRef<HTMLDivElement>(null);
 const { isOpen, onOpen, onClose } = useDisclosure();
 return (
  <Box w="100%" borderRadius="4px" mb="2rem">
   <Flex ref={ref} alignItems="baseline">
    <Box as="p" fontWeight="bold" ml="0.5rem" mb="1rem">
     {costDriver.identityName}
    </Box>
   </Flex>

   <Grid templateColumns="repeat(4, 1fr)" gap={4}>
    {costDriver?.data.length > 0 &&
     costDriver?.data.map((item: any, index: number) => {
      return (
       <Box key={index}>
        <Card
         item={item}
         onClick={(id) => {
          setDataOnClick(item);
          onOpen();
         }}
        />
       </Box>
      );
     })}
   </Grid>
   <Drawer isOpen={isOpen} size="lg" placement="right" onClose={onClose}>
    <DrawerOverlay />
    <DrawerContent>
     <ArrowForwardIcon
      position="absolute"
      top="4"
      right="2"
      fontSize={25}
      onClick={onClose}
      cursor="pointer"
      aria-label="Close drawer"
     />
     <DrawerHeader>{dataOnclick?.name}</DrawerHeader>
     <DrawerBody position="relative">
      <VStack spacing={6} align="stretch">
       <Box>
        <Heading fontSize="16px" fontWeight={500} mb=".5rem">
         Cost Driver
        </Heading>
        <VStack spacing={4} align="stretch">
         <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          {costDriver.headers
           ?.filter((item: any) => item.isDisplayOnPricing !== false)
           .map(({ name, label, convertName }: any) => {
            if (convertName === "name" || convertName === "description") {
             return (
              <GridItem w="100%" key={convertName}>
               {(convertName === "name" || convertName === "description") && (
                <VStack spacing={0} align="stretch" position="relative">
                 <Text
                  color="rgba(26, 26, 26, 0.36)"
                  fontSize="14px"
                  fontWeight={400}
                 >
                  {label}
                 </Text>
                 <Text fontSize="16px" fontWeight={400}>
                  {convertName === "name"
                   ? dataOnclick?.name
                   : dataOnclick?.description}
                 </Text>
                </VStack>
               )}
              </GridItem>
             );
            }
           })}
         </Grid>
        </VStack>
       </Box>
       {dataOnclick && dataOnclick?.customFields.length > 0 && (
        <>
         <Divider borderColor="rgba(26, 26, 26, 0.56)" />
         <Box>
          <Heading fontSize="16px" fontWeight={500} mb=".5rem">
           Additional Field
          </Heading>
          <VStack spacing={4} align="stretch">
           <Grid templateColumns="repeat(3, 1fr)" gap={6}>
            {orderBy(dataOnclick?.customFields, "sequence", "asc").map(
             ({ name, value, typeData, currencyCode, description }) => {
              const isRounding = typeData === 20;
              return (
               <GridItem w="100%">
                <VStack spacing={0} align="stretch">
                 <Text
                  color="rgba(26, 26, 26, 0.36)"
                  fontSize="14px"
                  fontWeight={400}
                 >
                  {name}
                 </Text>
                 <Text fontSize="16px" fontWeight={400}>
                  {isRounding
                   ? `${parseFloat(parseFloat(value).toFixed(2))}`
                   : `${typeData === 50 ? currencyCode : ""} ${
                      description ?? value
                     }`}
                 </Text>
                </VStack>
               </GridItem>
              );
             }
            )}
           </Grid>
          </VStack>
         </Box>
        </>
       )}

       <Divider borderColor="rgba(26, 26, 26, 0.56)" />
       <Box>
        <Heading fontSize="16px" fontWeight={500} mb=".5rem">
         Costing
        </Heading>
        <VStack spacing={4} align="stretch">
         <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          {costDriver.headers
           ?.filter((item: any) => item.isDisplayOnPricing !== false)
           .map(
            ({ name, label, convertName, maintenanceWithMasterData }: any) => {
             if (
              convertName !== "name" &&
              convertName !== "description" &&
              convertName !== "exchangeRateToCurrency" &&
              convertName !== "totalCostToCurrency"
             ) {
              const findCustomfieldName = dataOnclick?.customFields.find(
               (customField: any) => customField.name === convertName
              );
              if (
               convertName !== "name" &&
               convertName !== "description" &&
               convertName !== "exchangeRateToCurrency" &&
               convertName !== "totalCostToCurrency" &&
               convertName !== findCustomfieldName?.name
              )
               return (
                <>
                 <GridItem w="100%" key={convertName}>
                  <VStack spacing={0} align="stretch" position="relative">
                   <Text
                    color="rgba(26, 26, 26, 0.36)"
                    fontSize="14px"
                    fontWeight={400}
                   >
                    {label}
                   </Text>
                   <Text fontSize="16px" fontWeight={400}>
                    {convertName === "currency"
                     ? dataOnclick?.currency
                     : convertName === "totalCost"
                     ? dataOnclick?.totalCost.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                       })
                     : dataOnclick?.unitCost.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                       })}
                   </Text>
                  </VStack>
                 </GridItem>
                </>
               );
             }
            }
           )}
         </Grid>
        </VStack>
       </Box>
       <Divider borderColor="rgba(26, 26, 26, 0.56)" />
       <Box>
        <Heading fontSize="16px" fontWeight={500} mb=".5rem">
         Exchange Rate
        </Heading>
        <VStack spacing={4} align="stretch">
         <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          <GridItem w="100%">
           <VStack spacing={0} align="stretch">
            <Text
             color="rgba(26, 26, 26, 0.36)"
             fontSize="14px"
             fontWeight={400}
            >
             Currency
            </Text>
            <Text fontSize="16px" fontWeight={400}>
             {dataOnclick?.currency}
            </Text>
           </VStack>
          </GridItem>
          <GridItem w="100%">
           <VStack spacing={0} align="stretch">
            <Text
             color="rgba(26, 26, 26, 0.36)"
             fontSize="14px"
             fontWeight={400}
            >
             Rate
            </Text>
            <Text fontSize="16px" fontWeight={400}>
             {dataOnclick?.currencyConversionExchangeRate.toFixed(5)}
            </Text>
           </VStack>
          </GridItem>
         </Grid>
        </VStack>
       </Box>
       <Divider borderColor="rgba(26, 26, 26, 0.56)" />
       <Box>
        <VStack spacing={4} align="stretch">
         <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          <GridItem w="100%">
           <VStack spacing={0} align="stretch">
            <Text
             color="rgba(26, 26, 26, 0.36)"
             fontSize="14px"
             fontWeight={400}
            >
             Total Cost
            </Text>
            <Text fontSize="20px" fontWeight={600}>
             {`${
              dataOnclick?.convertedCurrency?.code
             } ${dataOnclick?.totalCostPricing.amount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
             })}`}
            </Text>
           </VStack>
          </GridItem>
         </Grid>
        </VStack>
       </Box>
      </VStack>
     </DrawerBody>
    </DrawerContent>
   </Drawer>
  </Box>
 );
};

export default CostDriverTable;
